import * as React from "react"
import {graphql, Link} from "gatsby"
import moment from "moment"
import P5Component from "../components/p5-component"

import Layout from "../components/layout"
import Seo from "../components/seo"

function truncateAddress(address) {
  if (!!address === false) return '';
  return address.substring(0,4) + '...' + address.substring(address.length-4);
}

const CurrentMarketStatus = ({ currentOwner, listingMarket, mintAddress, waveName }) => {
  if (!!currentOwner === false) return null;

  const marketLinks = {
    'Alpha.art': 'https://alpha.art/collection/playground-waves/',
    'Magic Eden': 'https://magiceden.io/marketplace?collection_symbol=playground_waves',
    Solanart: 'https://solanart.io/collections/playground'
  }

  return (
    <section className='market-status'>
      <div className='container clearfix'>
        <h3>Current Market Status</h3>
        <p><span className='darkmode-bold'>{waveName}</span> is currently owned by <a className='darkmode-bold' href={'https://alpha.art/user/' + currentOwner} target='_blank'>{currentOwner}</a></p>
        {listingMarket == 'unlisted' ?
          <p><span className='darkmode-bold'>{waveName}</span> is not currently listed for sale.</p>
          : <p><span className='darkmode-bold'>{waveName}</span> is currently listed for sale on {listingMarket}.</p>
        }
        <p>
          {listingMarket !== 'unlisted' &&
            <a className='button black' href={marketLinks[listingMarket]} target='_blank'>Buy Now</a>
          }
          <a className='button black' href={'https://alpha.art/t/' + mintAddress} target='_blank'>Make an Offer</a>
        </p>
      </div>
    </section>
  )
}

const TransactionsSection = ({ transactionHistory }) => {
  if (transactionHistory.length == 0) return null;

  const transactionTranslate = {
    cancelEscrow: 'Delist',
    initializeEscrow: 'Listing',
    exchange: 'Sale',
    LISTING: 'Listing',
    UNLIST: 'Delist'
  }
  const marketTranslate = {
    'alpha.art': 'Alpha.art',
    magiceden: 'Magic Eden',
    solanart: 'Solanart',
    'solanart.io': 'Solanart',
    'digitaleyes.market': 'Digital Eyes'
  }
  
  return (
    <section className='transaction-history'>
      <div className='container clearfix'>
        <h3>Transaction History</h3>
        <table>
          <thead>
          <tr>
            <th>Type</th>
            <th>From</th>
            <th>To</th>
            <th>Amount</th>
            <th>Marketplace</th>
            <th>Timestamp</th>
            <th>TxID</th>
          </tr>
          </thead>
          <tbody>

            {transactionHistory.map((tx) => (
              <tr key={tx.transactionId}>
                <td>{transactionTranslate[tx.transactionType]}</td>
                <td><a className='darkmode-bold' href={'https://explorer.solana.com/address/' + tx.seller} target='_blank'>{truncateAddress(tx.seller)}</a></td>
                <td><a className='darkmode-bold' href={'https://explorer.solana.com/address/' + tx.buyer} target='_blank'>{truncateAddress(tx.buyer)}</a></td>
                {!!tx.amount ?
                <td style={{ minWidth: '140px'}}>{(tx.amount * 0.000000001).toFixed(1)} SOL</td> :
                <td></td>
                }
                <td>{marketTranslate[tx.source]}</td>
                <td style={{ minWidth: '190px'}}>{moment(tx.timestamp).format('MMM Do \'YY')}</td>
                <td><a className='darkmode-bold' href={'https://explorer.solana.com/tx/' + tx.transactionId} target='_blank'>{truncateAddress(tx.transactionId)}</a></td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    </section>
  )
}

const WaveDetail = ({ data }) => {
  const [chainData, setChainData] = React.useState({});
  const [transactionHistory, setTransactionHistory] = React.useState([]);
  const [currentOwner, setCurrentOwner] = React.useState('');
  const [listingMarket, setListingMarket] = React.useState('unlisted');

  const activityURL = 'https://apis.alpha.art/api/v1/activity';
  const alphaPayload = {
    id: data.specificWavesJson.properties.mint_address,
    limit: 20,
    resourceType: 'TOKEN',
    tradingTypes: [
      'LISTING',
      'SALE',
      'OFFER',
      'UNLIST'
    ]
  };
  let waveNum = data.specificWavesJson.name.split(' #')[1];
  let liveUrl = "/wave-" + waveNum + "-live";
  const alphaTokenURL = 'https://apis.alpha.art/api/v1/token/' + data.specificWavesJson.properties.mint_address;
  const marketWallets = {
    '3D49QorJyNaL4rcpiynbuS3pRH4Y7EXEM6v6ZGaqfFGK': 'Solanart',
    '4pUQS4Jo2dsfWzt3VgHXy3H6RYnEDd11oWPiaM2rdAPw': 'Alpha.art',
    'GUfCR9mK6azb9vcpsxgXyj7XRPAKJd4KMHTTVvtncGgp': 'Magic Eden'
  }
  const magicNFTInfoURL = 'https://api-mainnet.magiceden.io/rpc/getNFTByMintAddress/' + data.specificWavesJson.properties.mint_address;
  const transactionURL = 'https://api-mainnet.magiceden.io/rpc/getGlobalActivitiesByQuery?q={%22%24match%22%3A{%22mint%22%3A%22' + data.specificWavesJson.properties.mint_address + '%22}%2C%22%24sort%22%3A{%22blockTime%22%3A-1}%2C%22%24skip%22%3A0}';

  React.useEffect(() => {
    getNFTDataWithFetch();
    getTransactionHistoryWithFetch();
  }, []);

  const getNFTDataWithFetch = async () => {
    const magicChainResponse = await fetch(magicNFTInfoURL);
    const meJson = await magicChainResponse.json();
    if ('results' in meJson) {
      const meData = meJson.results;
      setChainData(meData);
      console.log(meData);
      if (meData.owner in marketWallets) {
        setListingMarket(marketWallets[meData.owner]);
      } else if ('escrowPubkey' in meData && !!meData['escrowPubkey']) {
        setListingMarket('Magic Eden');
      }
    }

    const alphaTokenResponse = await fetch(alphaTokenURL);
    const alphaTokenJson = await alphaTokenResponse.json();
    if ('token' in alphaTokenJson) {
      setCurrentOwner(alphaTokenJson.token.currentOwner);
    }

  };

  const getTransactionHistoryWithFetch = async () => {
    const transactionResponse = await fetch(transactionURL);
    const tJson = await transactionResponse.json();
    let allTransactions = (tJson.results || []).map((result) => {
      let amount = null;
      let timestamp = null;
      if ('parsedTransaction' in result) {
        amount = result.parsedTransaction.total_amount;
        console.log(result.parsedTransaction.blockTime);
        timestamp = moment(result.parsedTransaction.blockTime.toString(), 'X').format();
      } else if ('parsedList' in result) {
        amount = result.parsedList.amount;
        timestamp = moment(result.createdAt).format();
      } else if ('parsedUnlist' in result) {
        timestamp = moment(result.createdAt).format();
      }
      return {
        amount,
        buyer: result.buyer_address,
        seller: result.seller_address,
        source: result.source,
        timestamp,
        transactionId: result.transaction_id,
        transactionType: result.txType
      }
    });
    console.log(allTransactions);

    const alphaResponse = await fetch(activityURL, {
      method: 'POST',
      headers: {
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(alphaPayload),
    });
    const alphaJson = await alphaResponse.json();
    (alphaJson.history || []).forEach(transaction => {
      let transactionId = transaction.signature;
      if (!allTransactions.some(t => t.transactionId == transactionId)) {
        let amount = null;
        if (transaction.price) amount = parseInt(transaction.price);
        allTransactions.push({
          amount: amount,
          buyer: (transaction.tradingType === 'LISTING' || transaction.tradingType === 'UNLIST') ? '' : transaction.toPubkey,
          seller: transaction.user,
          source: transaction.marketplace,
          timestamp: transaction.createdAt,
          transactionId: transaction.signature,
          transactionType: transaction.tradingType
        });
      }
    });

    allTransactions.forEach(transaction => {
      console.log(transaction.timestamp);
      console.log(moment(transaction.timestamp));
    });

    allTransactions = allTransactions.sort((a, b) => moment(a.timestamp) < moment(b.timestamp) ? 1 : -1);

    setTransactionHistory(allTransactions);
  };

  console.log(transactionHistory);

  return (
    <Layout>
      <Seo title={data.specificWavesJson.name} />
      <section className='section-title white collection'>
        <div className='container clearfix'>
          <div className='col40 left artwork-info'>
            <div className='clearfix tags'>
              <Link to='/waves-gallery/' className='button black left'>back to gallery</Link>
              <a href={liveUrl} className='button black left' target='_blank'>Live Animation</a>

            </div>
            <h1>{data.specificWavesJson.name}</h1>
            <p className='big'>{data.specificWavesJson.description}</p>
            <div className='details'>
              <a className='button transparent'>Generative art</a>
              <a className='button transparent'>Created via p5.js</a>
              <a className='button transparent'>1200x1200</a>
              <a className='button transparent'>Edition of 1000</a>
            </div>

            <div className='features'>
              <h4>Features</h4>
              <ul>
                <li>Hash: {data.specificWavesJson.attributes.hash_id}</li>
                <li>Number of Colors: {data.specificWavesJson.attributes.Number_of_colors}</li>
                <li>Width: {data.specificWavesJson.attributes.width}</li>
                <li>Thickness: {data.specificWavesJson.attributes.stroke_thickness}</li>
                <li>Speed: {data.specificWavesJson.attributes.speed}</li>
                <li>Jitter: {data.specificWavesJson.attributes.jitter}</li>
                <li>Hue: {data.specificWavesJson.attributes.hue}</li>
                <li>Saturation: {data.specificWavesJson.attributes.saturation}</li>
                <li>Brightness: {data.specificWavesJson.attributes.brightness}</li>
                <li>Alpha: {data.specificWavesJson.attributes.alpha}</li>
              </ul>
            </div>

          </div>
          <div className='col60 left image wave'>
            <P5Component attributes={data.specificWavesJson.attributes} />
          </div>
        </div>
      </section>

      <CurrentMarketStatus
        currentOwner={currentOwner}
        listingMarket={listingMarket}
        mintAddress={data.specificWavesJson.properties.mint_address}
        waveName={data.specificWavesJson.name}
      />
      <TransactionsSection
        transactionHistory={transactionHistory}
      />
    </Layout>
  )
}

export const query = graphql`
  query MyQuery($id: String) {
    specificWavesJson(id: {eq: $id}) {
      id
      name
      description
      attributes {
        Number_of_colors
        alpha
        brightness
        hash_id
        hue
        jitter
        saturation
        speed
        stroke_thickness
        width
      }
      properties {
        mint_address
      }
    }
  }
`

export default WaveDetail
